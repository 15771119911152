<template>
  <footer class="footer">
    <div class="content_box">
      <div class="title">落落的碎碎念</div>
      <div class="text">
        <a
          target="_blank"
          href="https://beian.miit.gov.cn"
        >网站备案号：蜀ICP备2021023239号</a>
      </div>
    </div>
    <div class="live_box">
    </div>
  </footer>
</template>
<script lang="ts">

export default defineComponent({
  name: 'Footer',
  components: {
  },
  setup() {
    const data = reactive({
      liveConfig: {
        direction: 'right',
        size: 300,
        homePage: 'https://github.com/sos-luoluo',
      },
    });

    return { data };
  },
});
</script>
<style lang="less" scoped>
@import "../../styles/config.less";
.footer {
  width: 100%;
  border-top: 0.01rem dashed @c_bg_line;
  .content_box {
    width: 100%;
    height: 100%;
    max-width: @minWidth;
    margin: auto;
    padding: 0.15rem 0.3rem;
    .title {
      padding: 0.05rem 0;
      font-size: @f_text;
      color: @c_bg_title;
      line-height: 1.5;
      text-align: center;
      font-weight: bold;
    }
    .text {
      padding: 0.05rem 0;
      font-size: @f_tips;
      color: @c_bg_text;
      line-height: 1.5;
      text-align: center;
    }
  }
  .live_box {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
    font-size: 0.2rem;
    :deep(.vue-live2d-tip) {
      min-height: auto;
      line-height: 1.3;
      margin-top: -0.2rem;
      padding: 0.05rem 0.1rem;
      font-size: 0.14rem;
    }
  }
}
@media (max-width: 1200px) {
  .footer {
    .content_box {
      padding: 0.1rem 0.15rem;
    }
    .live_box {
      display: none;
    }
  }
}
</style>
